.col-centered{
  float: none;
  margin: 0 auto;
}

.main-div{
  width: 1500px;
  height: 900px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-login {
  border: 5px solid #f3f3f3!important; /* Light grey */
  border-top: 5px solid #3498db!important; /* Blue */
  border-radius: 50%;
  width: 30px!important;
  height: 30px!important;
  animation: spin 2s linear infinite;
  margin-top: 3px;
  float: right;
}

.loader-cloth{
  position: absolute;
  left: 48%;
  top: 34px;
}

.loader-upload-image{
  position: absolute;
  left: 44%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.position-box{
  width: 20px;
  height: 20px;
  font-size: 15px;
  padding: 0!important;
  padding-top: 2px!important;
  text-align: center;
  line-height: 15px;
  border: solid 1px black;
  background-color: white;
}

.cursorCrosshair{
  cursor: crosshair;
}


/*.auth-wrapper{
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 1em;
  text-align: left;
  width: 600px;
}*/

.auth-wrapper{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 100vh;
  background: #1C8EF9 !important;
}

.auth-inner {
	width: 450px;
	margin: auto;
	background: #ffffff;
	box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
	padding: 40px 55px 45px 55px;
	border-radius: 15px;
	transition: all .3s;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, label, span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}